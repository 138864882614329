<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>

		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="仓库编号" prop="pharmacyNo">
						<Input
							v-model="formData.pharmacyNo"
							placeholder="请输入仓库编号"
							:disabled="true"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="仓库名称" prop="pharmacyName">
						<Input
							v-model="formData.pharmacyName"
							placeholder="请输入仓库名称"
							:disabled="true"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="温度(℃)" prop="temperature">
						<Input v-model="formData.temperature" placeholder="请输入温度" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="湿度(%)" prop="humidity">
						<Input v-model="formData.humidity" placeholder="请输入湿度" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="记录时间" prop="recordDate">
						<DatePicker
							type="datetime"
							placeholder="请输入记录时间"
							:clearable="false"
							v-model="formData.recordDate"
							style="width: 100%"
						></DatePicker>
					</FormItem>
				</Col>
			</Row>
		</Form>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" :loading="loading" @click="onOk">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/pharmacy/humiture/index"

const { apiAdd } = api

export default {
	name: "Add",
	props: ["visible", "typeList", "pharmacyId", "pharmacyName", "pharmacyNo"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "新增记录",
			rules: {
				pharmacyName: {
					required: true,
					message: "仓库名称不能为空",
					trigger: "blur"
				},
				pharmacyNo: {
					required: true,
					message: "仓库编号不能为空",
					trigger: "blur"
				},
				temperature: {
					required: true,
					message: "温度不能为空",
					trigger: "blur"
				},
				humidity: [
					{ required: true, message: "湿度不能为空", trigger: "blur" }
				],
				recordDate: {
					required: true,
					type: "date",
					message: "记录时间不能为空",
					trigger: "change"
				}
			},
			ctms_config: {
				onchange: html => {
					this.formData.content = html
				},
				onblur: html => {
					this.formData.content = html
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				Object.assign(this.formData, {
					pharmacyName: this.pharmacyName,
					pharmacyNo: this.pharmacyNo
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.pharmacyId) {
							res = await apiAdd({
								...this.formData,
								pharmacyId: this.pharmacyId
							})
						} else {
							res = await apiAdd({
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
