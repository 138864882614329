import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/pharmacy"
export default {
	...commonApi(moduleName),
	// 获取详情分页
	apiGetPage: data =>
		request(`/pharmacy/${data.pharmacyId}/temperature`, {
			method: "get",
			params: data
		}),
	// 获取仓库详情
	apiGetPharmacyDetail: data =>
		request(`/pharmacy/${data.pharmacyId}`, {
			method: "get",
			params: data
		}),
	// 添加记录
	apiAdd: data =>
		request(`/pharmacy/${data.pharmacyId}/temperature`, {
			method: "post",
			body: data
		}),
	// 删除记录
	apiDelete: data =>
		request(`/pharmacy/${data.pharmacyId}/temperature/${data.tempId}`, {
			method: "delete"
		})
}
